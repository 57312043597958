<template>
  <div class="sider-container" v-if="visible">
    <component :is="SiderMenu" />
  </div>
</template>

<script>
export default {
  computed: {
    SiderMenu() {
      return this.$tis.layouts.SiderMenu;
    },
    visible() {
      return !!this.SiderMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.sider-container {
  max-width: 620px;
  height: 100%;
  overflow: hidden;
}
</style>