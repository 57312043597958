<template>
  <div class="tisc-login-page">
    <div class="tisc-login-page-header">
      <h1>NBA各球队当家球星介绍</h1>
      <h2>infomation platform</h2>
    </div>
    <div class="tisc-login-wrapper">
      <div class="tisc-login" v-show="toLogin">
        <div class="tisc-login-header">用户登录</div>
        <div class="tisc-login-inpt">
          <h2 class="tisc-login-car-info">筹划决策要素（球队简介01）</h2>
          <el-autocomplete
            class="inline-input tisc-login-input-username"
            v-model="username"
            :fetch-suggestions="querySearch"
            placeholder="用户名"
            @select="handleSelect"
          ></el-autocomplete>
          <!-- <el-input
            class="tisc-login-input-username"
            placeholder="用户名"
            v-model="username"
          ></el-input> -->
          <span v-if="visible" class="iconfont absol" @click="tabpass"
            >&#xe611;</span
          >
          <span v-else class="iconfont absol" @click="tabpass">&#xe610;</span>
          <el-input
            v-if="visible"
            type="password"
            v-model="password"
            placeholder="密码"
          ></el-input>
          <el-input
            v-else
            type="text"
            class="inline-input tisc-login-input-password"
            v-model="password"
            placeholder="密码"
          ></el-input>
          <div class="divMassage" v-show="massageTo">密码错误，请重新输入</div>
        </div>
        <div class="tisc-login-btns">
          <button class="tisc-cancel-btn">取消</button>
          <button type="primary" class="tisc-login-btn" @click.stop="goLogin">
            登录
          </button>
        </div>
      </div>
      <div class="tisc-login-bg-layer2"></div>
      <div class="tisc-login-bg-layer3"></div>
    </div>

    <div class="zhang-wrapper" v-show="addLogin">
      <div class="zhang-gologin">
        <div class="zhang-goheader">席位选择</div>
      </div>
      <div class="zhang-content">
        <div
          class="box"
          @wheel.prevent="ontab()"
          :style="{ top: scrollTop + 'px' }"
        >
          <li
            class="li"
            v-for="item in data"
            :key="item.id"
            :class="item.id == act ? 'act' : ''"
            @click="onClick(item)"
          >
            <div class="zhang-btn">
              {{ item.name
              }}<button class="btn" v-show="act == item.id" @click="login">
                确认
              </button>
            </div>
          </li>

          <img src="../assets/背景1.png" alt="" class="imgone" />
          <img src="../assets/背景2.png" alt="" class="imgtwo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-page",
  data() {
    return {
      massageTo: false,
      toLogin: true,
      addLogin: false,
      username: "",
      password: "",
      wheelFlag: false,
      prevWheel: null,
      suggestions: [
        { value: "李一洋", id: "11111" },
        {
          value: "球队助教1",
          id: "22222",
        },
      ],
      data: [
        {
          id: 0,
          name: "勒布朗·詹姆斯",
        },
        {
          id: 1,
          name: "史蒂芬·库里",
        },
        {
          id: 2,
          name: "扬尼斯·阿德托昆博",
        },
        {
          id: 3,
          name: "卢卡·东契奇",
        },
        {
          id: 4,
          name: "凯文·杜兰特",
        },
        {
          id: 5,
          name: "勒布朗·詹姆斯",
        },
        {
          id: 6,
          name: "NBA 球星 6",
        },
        {
          id: 7,
          name: "NBA 球星 7",
        },
        {
          id: 8,
          name: "NBA 球星 8",
        },
        {
          id: 9,
          name: "NBA 球星 9",
        },
        {
          id: 10,
          name: "NBA 球星 10",
        },
      ],
      act: 0,
      list: [],
      visible: true,
      scrollTop: null,
      scrollDefaultTop: 0,
      scrollLiHeight: 0,
      start: new Date().getTime(),
      loadTime: "",
    };
  },
  beforeCreate() {
    // 组件创建前
    this.start = new Date().getTime();
    console.log("组件挂载前", this.start);
  },
  mounted() {
    // 组件挂载后
    let end = new Date().getTime();
    this.loadTime = this.end - this.start + "ms";
  },
  methods: {
    tabpass() {
      this.visible = !this.visible;
    },
    //显示隐藏
    goLogin() {
      //密码框判断
      if (this.password == "test") {
        this.addLogin = !this.addLogin;
        this.toLogin = !this.toLogin;
        this.$nextTick(() => {
          this.scrollDefaultTop = document.querySelector(
            ".zhang-content .box"
          ).offsetTop;
          this.scrollTop = this.scrollDefaultTop;
          this.scrollLiHeight = document.querySelector(
            ".zhang-btn"
          ).offsetHeight;
        });
      } else {
        this.massageTo = true;
        setTimeout(() => {
          this.massageTo = false;
        }, 3000);
      }
    },
    // 席位选择逻辑
    ontab(e) {
      if (!this.wheelFlag) {
        this.wheelFlag = true;
        setTimeout(() => {
          this.wheelFlag = false;
        }, 80);
        e = e || window.event;
        if (e.wheelDelta > 0) {
          console.log("向上滚动");
          if (this.act > 0 && this.prevWheel === "up") {
            this.scrollTop = this.scrollTop + this.scrollLiHeight;
            this.act--;
          }
          this.prevWheel = "up";
          if (this.act == this.data.length - 1) {
            // this.scrollTop = this.scrollDefaultTop;
            // this.act = 0;
          } else if (this.scrollTop == this.scrollLiHeight) {
            // pass
          }
        }
        if (e.wheelDelta < 0) {
          console.log("向下滚动");
          if (this.act < this.data.length - 1 && this.prevWheel === "down") {
            this.scrollTop = this.scrollTop - this.scrollLiHeight;
            this.act++;
          }
          this.prevWheel = "down";
          if (this.act == this.data.length - 1) {
            // this.scrollTop = this.scrollDefaultTop;
            // this.act = 0;
          } else if (this.scrollTop == this.scrollLiHeight) {
            // pass
          }
        }
        // console.log("act", this.act);
      }
    },
    onClick(item) {
      this.list = item.name;
      if (this.act < item.id) {
        this.scrollTop =
          this.scrollTop - (item.id - this.act) * this.scrollLiHeight;
      } else if (this.act > item.id) {
        this.scrollTop =
          this.scrollTop + (this.act - item.id) * this.scrollLiHeight;
      }
      this.act = item.id;
      // if (this.act == this.data.length - 1 || this.act == 0) {
      //   debugger;
      //   this.scrollTop = this.scrollDefaultTop;
      //   this.act = 0;
      // }
    },
    login() {
      // 登录调用接口逻辑
      this.$tis.axios
        .post("/Seatselection", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          console.log("登录成功", res);
          this.$emit("login-success", {
            username: this.username,
            password: this.password,
          });
        })
        .catch((err) => {
          console.log("登录失败", err);
          this.$emit("login-fail", {
            username: this.username,
            password: this.password,
          });
        });
      // 登录调用接口逻辑
    },
    cancel() {
      this.username = "";
      this.password = "";
      this.$emit("cancel");
    },
    querySearch(query, cb) {
      cb(this.suggestions);
    },
    handleSelect(item) {
      console.log("选择用户：", item);
    },
    changeTheme() {
      const styleLink = document.getElementById("tis-design-theme");
      const href = styleLink.getAttribute("href");

      if (href.indexOf("theme-chalk") !== -1) {
        // 变成 theme-blue
        styleLink.setAttribute(
          "href",
          "http://tis.inscloudtech.com:8000/tis-ui/theme-blue/index.css"
        );
      } else {
        // 变回 theme-chalk
        styleLink.setAttribute(
          "href",
          "http://tis.inscloudtech.com:8000/tis-ui/theme-chalk/index.css"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tisc-login-page {
  background-image: url("../assets/login-page-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &-header {
    text-align: center;
    margin-top: 5%;
    h1 {
      font-size: 56px;
      color: #ffffff;
      letter-spacing: 4px;
      margin: 0;
    }
    h2 {
      font-size: 24px;
      color: #acd5ff;
      letter-spacing: 5.49px;
      opacity: 0.3;
      margin: 0;
    }
  }
}
.tisc-login-wrapper {
  width: 516px;
  height: 343px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tisc-login-bg-layer2 {
  background-image: url("../assets/login-layer-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 496px;
  height: 324px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.tisc-login-bg-layer3 {
  background-image: url("../assets/login-layer-3.png");
  background-repeat: no-repeat;
  background-size: 140%;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.tisc-login-car-info {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 20px;
}

.tisc-login {
  background: transparent;
  width: 496px;
  height: 324px;
  margin: 0 auto;
  padding: 4px 67px;
  background-image: url("../assets/login-layer-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  &-header {
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 30px;
    color: #ffffff;
  }

  &-inpt {
    margin-bottom: 32px;
    .tisc-login-input-username {
      margin-bottom: 16px;
      height: 48px;
      width: 100%;
    }

    .tisc-login-input-pwd {
      height: 48px;
      .el-input__inner {
        height: 48px;
      }
    }

    input {
      height: 48px;
    }
  }

  &-btns {
    display: flex;
    justify-content: space-between;

    & > button {
      width: 168px;
      height: 48px;
      font-size: 20px;
      border-radius: 0;
    }

    .tisc-cancel-btn {
      background-color: #eee;
      border: none;
      color: #000;
    }
    .tisc-cancel-btn:hover {
      cursor: pointer;
      color: #ffe88b;
    }
    .tisc-cancel-btn:hover {
      color: #ffe88b;
    }

    .tisc-login-btn {
      background-image: url("../assets/btn.png");
      background-repeat: no-repeat;
      background-size: cover;
      font-size: 20px;
      // color: #382c0c;
      border: none;
    }
  }
}
.btn:hover {
  color: #ffe88b;
}

.tisc-login-btn:hover {
  color: #ffe88b;
  cursor: pointer;
}
.zhang-wrapper {
  width: 516px;
  height: 343px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zhang-gologin {
  background: transparent;
  width: 496px;
  height: 324px;
  margin: 0 auto;
  padding: 4px 67px;
  background-image: url("../assets/login-layer-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  .zhang-goheader {
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 30px;
    color: #ffffff;
  }
}
.zhang-content {
  width: 95%;
  height: 84%;
  background-color: #051936;
  position: relative;
  top: 44px;
  left: 13px;
  z-index: 999;
  overflow: hidden;
  .box {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 134px;
    right: -17px;
    bottom: 0;
    // overflow-x: hidden;
    // overflow-y: scroll;

    .li {
      width: 80%;
      height: 42px;
      margin: 0px auto;
      line-height: 42px;
      opacity: 0.3;
      // border: 1px solid #979797;

      font-size: 14px;
      color: #6cc8ff;
      text-align: center;
      list-style: none;
      z-index: 999;
    }
    .li:hover {
      cursor: pointer;
    }
    li.act {
      opacity: 0.9;
      font-size: 20px;
      color: #ffe55e;
      transition: all 0.3s;
    }
  }
}
.btn {
  background: none;
  border: none;
  font-size: 20px;
  color: #ffe55e;
  position: absolute;
  right: 42px;
  top: 9px;
  z-index: 999;
  height: 25px;
  line-height: 25px;
}
.btn:hover {
  cursor: pointer;
}
.imgone {
  width: 296px;
  height: 70px;
  position: fixed;
  left: 50px;
  top: 154px;
  z-index: -999;
}
.imgtwo {
  width: 300px;
  height: 42px;
  position: fixed;
  left: 155px;
  top: 178px;
  z-index: -999;
}
.zhang-btn {
  text-align: left;
  padding-left: 100px;
  position: relative;
}
.tisc-login-btns .tisc-login-btn :hover {
  color: #ffe88b;
}

::v-deep
  .el-input__icon
  iconfont
  icon-yincang
  .el-input__icon
  iconfont
  icon-xianshi {
  margin-right: 30px;
  z-index: 9999;
}

.absol {
  position: absolute;
  z-index: 99999;

  top: 175px;
  right: 80px;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 25px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}
.divMassage {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 217px;
  left: 0;
  z-index: 999;
  border-radius: 10px;
  color: red;
  line-height: 30px;
  text-align: center;
}
</style>
