<template>
  <div class="widgets-panel-container" v-if="visible">
    <component :is="WidgetsPanel" />
  </div>
</template>

<script>
export default {
  computed: {
    WidgetsPanel() {
      return this.$tis.layouts.WidgetsPanel;
    },
    visible() {
      return !!this.WidgetsPanel && this.$tis.isVisibleWidgetsPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets-panel-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
}
</style>