<template>
  <transition name="slide-fade">
    <div class="footer-container" v-if="visible">
      <component :is="Footer" />
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    Footer() {
      return this.$tis.layouts.Footer;
    },
    visible() {
      return (
        !!this.Footer && this.$tis.isMainApp() && !this.$tis.isFullScreen()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 290;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>