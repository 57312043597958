<template>
  	<el-dialog title="消息框" :visible="isVisible" :modal="false" @close="onClose">
      <!-- <p class="xiaoxi-text">{{objData.content}}</p>
      <div slot="footer" class="dialog-footer">
        <el-input v-model="content" autocomplete="off"></el-input>
        <el-button type="primary" @click="handleComfirm">{{ objData.isHasReply? "回复" : "确定" }}</el-button>
      </div> -->
	   <p class="xiaoxi-text">werrr</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleComfirm">确认</el-button>
      </div>
    </el-dialog>
</template>

<script>
export default {
	data() {
		return {
			visible: true
		};
	},
	created() {
	},
	computed: {
		isVisible(){
			return this.$tis.isVisibleLookAtMsgBox()
		}
	},
	methods: {
		//当点击弹窗里面当确定执行此函数
		handleComfirm(){
		//隐藏弹框
		//   this.isVisible = false
    			this.isVisible  = false;
    			this.$tis.closeLookAtMsgBox()
		},
		onClose(){
			this.isVisible  = false;
			this.$tis.closeLookAtMsgBox()
		}
	}
};
</script>

<style lang="scss" scoped>
.xiaoxi-text {
  font-size: 14px;
  line-height: 20px;
  background: #202020;
  padding: 18px 20px;
  color: #fff;
  height: 170px;
}
::v-deep {
  .el-dialog {
    background: rgba(0, 0, 0, 0.8);
    width: 496px;
    height: 332px;
    border-radius: 0;
    top: 50%;
    margin-top: -166px !important;
    margin-bottom:0 !important;
  }
  .el-dialog__title {
    color: #fff;
    font-size: 18px;
  }
  .el-dialog__body {
    padding: 8px 20px;
  }
  .el-input {
	  width: 71%;
    margin-right: 20px;
    border-radius:0px;

  }
  .el-input__inner {
    color: #fff;
    background: #202020;
    border: 1px solid rgba(255,255,255,0.22);
  }
  .el-button--primary {
    width: 120px;
    height: 42px;
    background: url("../assets/btn.png") no-repeat;
    border: none;
    background-color: none;
    font-size: 18px;
    color: #382C0C;
     border-radius:0px;

  }
  .el-dialog__header {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.8);
  }
  .el-dialog__headerbtn {
    top: 8px;
    font-size: 24px;
  }
  .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close{
    color: #f7c829;
  }
  .dialog-footer {
    display: flex;
	justify-content: flex-end 
  }
}
</style>
