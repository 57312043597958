<template>
<transition name="slide-fade">
<div v-if="visible" class="Message-Box" v-clickoutside="handleClose">
        <component :is="MessageBox" />
 </div>
  </transition>
</template>
<script>
const clickoutside = {
    // 初始化指令
    bind(el, binding) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};

export default {
directives: {clickoutside},
 data() {
   return {};
  },
  computed:{
      MessageBox() {
      return this.$tis.layouts.MessageBox;
    },
      visible() {
      return !!this.MessageBox && this.$tis.isVisibleMessageBox();
    },

  },
   mounted() {},
 methods: {
     // 引用点击空白消失
       handleClose() {
      this.$tis.togtomeslist();
    },
 },
};
</script>
<style lang='scss' scoped>
.Message-Box{
  position: fixed;
  top: 100px;
  right:8%;
  z-index: 999;
  width: 368px;
  height: 552px;
  background: rgba(0,0,0,0.85); 
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>