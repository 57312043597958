<template>
  <div class="widgets-view-container" v-if="screenView">
    <component :is="screen" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // screenView:false
    };
  },
  computed: {
    screen() {
      return this.$tis.layouts.screenView;
    },
    screenView() {
      return this.$tis.isAppScreen();
    },
  },
  //  mounted(){
  //    this.screenView = this.$tis.isAppScreen();
  //    console.log('==================',this.screenView)
  //  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.widgets-view-container {
  // position: absolute;
  // top: 0px;
  // right: 0px;
  // height: 100%;
  // left: 0;
  // z-index: 100;
  // pointer-events: none;
}
</style>
