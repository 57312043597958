<template>
  <component :is="AppView" />
</template>

<script>
export default {
  computed: {
    AppView() {
      return this.$tis.layouts.AppView;
    },
  },
};
</script>