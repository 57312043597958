<template>
  <div class="historical-news-container" v-if="visible">
    <component :is="HistoricalNews" />
  </div>
</template>

<script>
export default {
  computed: {
    HistoricalNews() {
      return this.$tis.layouts.HistoricalNews;
    },
    visible() {
      return !!this.HistoricalNews &&  this.$tis.isVisibleHistoricalNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.historical-news-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  background: #000;
}
</style>