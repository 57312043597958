<template>
  <div class="status-bar-container">
    <transition name="slide-fade">
      <component :is="StatusBar" v-show="visible && !isFullScreen" />
    </transition>
    <img
      class="exitFullScreen"
      src="../../assets/exitFullScreen.png"
      v-show="isFullScreen"
      @click="onExitFullScreen"
    />
  </div>
</template>

<script>
export default {
  computed: {
    StatusBar() {
      return this.$tis.layouts.StatusBar;
    },
    visible() {
      return !!this.StatusBar;
    },
    isFullScreen() {
      return this.$tis.isFullScreen();
    },
  },
  methods: {
    onExitFullScreen() {
      this.$tis.fullScreen(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.status-bar-container {
  width: 100%;
  //max-height: 25px;
  //overflow: hidden;
  flex: none;
  position: fixed;
  z-index: 92;
}
.exitFullScreen {
  top: 15px;
  right: 15px;
  z-index: 200;
  position: fixed;
  width: 41px;
  cursor: pointer;
  float: right;
  margin: 15px;
}
.device-mobile .exitFullScreen {
  width: 60px;
  top: 30px;
  right: 50px;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
