<template>
  <div>
    <!-- 页面跳转 -->
    <LoginPage v-if="!hasLogin" @login-success="login" @login-fail="login"
      >login</LoginPage
    >
    <!-- <SeatSelection v-show="hasLogin"></SeatSelection> -->
    <BasicLayout v-show="hasLogin">
      <StatusBarContainer slot="status-bar" />
      <SiderContainer slot="sider" v-if="false" />
      <div slot="header" v-if="false">
        <NavBarContainer />
        <AppBarContainer />
      </div>
      <WidgetsViewContainer slot="fixed-content" />
      <screenViewcontainer />
      <AppViewContainer />
      <WidgetsPanelContainer />
      <AppsPanelContainer />
      <!-- 任务看板 -->
      <!-- <TaskKanbanContainer /> -->
      <!-- 消息设置 -->
      <WidgetsSysSetting />
      <!-- 右侧消息通知 -->
      <NotificationWholeContainer />
      <!-- -->
      <HistoricalNewsContainer />
      <!-- 登陆-->
      <Login />
      <Border />
      <!-- 消息回复框 -->
      <DialogBox />
      <LookAtMsgBox />
      <!-- 搜索盒子 -->
      <SearchBoxContainer class="namesearc" />
      <VoiceBoxContainer />
      <!-- 底部菜单栏 -->
      <footer-container />
      <DevTools />
      <NewsNotification />
      <AlertSettingPage v-if="alertSettingPageVisible" />
      <!-- 人工定位 -->
      <locationTo />
      <!-- 消息 -->
      <MessageBoxifshow />
      <!-- 通知校时 -->
      <ToListMailShow />
      <!-- 采点 -->
      <Miningpointif />
    </BasicLayout>
  </div>
</template>

<script>
import BasicLayout from "./layouts/BasicLayout";
import StatusBarContainer from "./layouts/StatusBarContainer";
import SiderContainer from "./layouts/SiderContainer.vue";
import AppViewContainer from "./layouts/AppViewContainer.vue";
import NavBarContainer from "./layouts/NavBarContainer.vue";
import AppBarContainer from "./layouts/AppBarContainer.vue";
import WidgetsPanelContainer from "./layouts/WidgetsPanelContainer.vue";
import AppsPanelContainer from "./layouts/AppsPanelContainer.vue";
import WidgetsViewContainer from "./layouts/WidgetsViewContainer.vue";
import DevTools from "./DevTools.vue";
// import TaskKanbanContainer from "./layouts/TaskKanbanContainer.vue";
import WidgetsSysSetting from "./layouts/WidgetsSysSetting.vue";
import Login from "./layouts/LoginContainer.vue";
import NotificationWholeContainer from "./layouts/NotificationWholeContainer.vue";
import HistoricalNewsContainer from "./layouts/HistoricalNewsContainer.vue";
import Border from "../components/border.vue";
import DialogBox from "../components/DialogBox.vue";
import LookAtMsgBox from "../components/LookAtMessage.vue";
import NewsNotification from "../components/NewsNotification.vue";
import LoginPage from "../components/LoginPage.vue";
import SearchBoxContainer from "./layouts/SearchBoxContainer.vue";
import VoiceBoxContainer from "./layouts/VoiceBoxContainer.vue";
import FooterContainer from "./layouts/FooterContainer.vue";
// import SeatSelection from './layouts/SeatSelection.vue';
import AlertSettingPage from "../components/AlertSettingPage.vue";
import locationTo from "./layouts/location.vue";
import MessageBoxifshow from "./layouts/MessageBoxif.vue";
import ToListMailShow from "./layouts/toListMailif.vue";
import Miningpointif from "./layouts/Miningpointif.vue";
import screenViewcontainer from "./layouts/screenViewContainer.vue";
export default {
  components: {
    AlertSettingPage,
    BasicLayout,
    StatusBarContainer,
    WidgetsPanelContainer,
    SiderContainer,
    NavBarContainer,
    AppBarContainer,
    WidgetsViewContainer,
    AppViewContainer,
    AppsPanelContainer,
    DevTools,
    // TaskKanbanContainer,
    WidgetsSysSetting,
    Border,
    NotificationWholeContainer,
    HistoricalNewsContainer,
    Login,
    DialogBox,
    LookAtMsgBox,
    NewsNotification,
    LoginPage,
    SearchBoxContainer,
    VoiceBoxContainer,
    FooterContainer,
    // SeatSelection
    locationTo,
    MessageBoxifshow,
    ToListMailShow,
    Miningpointif,
    screenViewcontainer,
  },
  data() {
    return {
      hasLogin: sessionStorage.getItem("token") === "success",
    };
  },
  mounted() {
    this.$tis.bus.isMobile = false;
  },
  computed: {
    alertSettingPageVisible() {
      return this.$tis.bus.alertSettingPageVisible;
    },
  },
  methods: {
    login({ username, password }) {
      console.log("用户名：", username);
      console.log("密码：", password);
      // 登录逻辑
      this.hasLogin = true;
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>
