<template>
  <div v-if="isDev">
    <el-button
      slot="reference"
      type="success"
      size="mini"
      class="switch"
      @click="onToggle"
    >
      DevTool
    </el-button>
    <el-dialog :class="'bg-color'" :visible.sync="show">
      <div class="devtools">
        <el-tabs type="card">
          <el-tab-pane label="添加应用">
            <div class="card">
              <el-form ref="form" :model="app" label-width="150px">
                <el-form-item label="应用唯一id" required>
                  <el-input v-model="app.id"></el-input>
                </el-form-item>
                <el-form-item label="应用名称" required>
                  <el-input v-model="app.name"></el-input>
                </el-form-item>
                <el-form-item label="应用入口地址" required>
                  <el-input v-model="app.entry"></el-input>
                </el-form-item>
                <el-form-item label="应用图标地址" required>
                  <el-input v-model="app.icon"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onAddApp">添加</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加浮窗组件">
            <div class="card">
              <el-form ref="form" :model="widget" label-width="150px">
                <el-form-item label="浮窗组件唯一id" required>
                  <el-input v-model="widget.id"></el-input>
                </el-form-item>
                <el-form-item label="浮窗组件名称" required>
                  <el-input v-model="widget.name"></el-input>
                </el-form-item>
                <el-form-item label="浮窗组件入口地址" required>
                  <el-input v-model="widget.entry"></el-input>
                </el-form-item>
                <el-form-item label="浮窗组件图标地址" required>
                  <el-input v-model="widget.icon"></el-input>
                </el-form-item>
                <el-form-item label="浮窗组件位置" required>
                  <el-radio-group v-model="widget.position">
                    <el-radio label="right">右侧</el-radio>
                    <el-radio label="bottom">底部</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onAddWidget"
                    >添加</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="添加浮窗组件" name="second">配置管理</el-tab-pane> -->
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      app: {
        name: "",
        icon: "",
        id: "",
        entry: "",
      },
      widget: {
        name: "",
        icon: "",
        id: "",
        entry: "",
        position: "",
      },
    };
  },
  computed: {
    isDev() {
      const urlParams = new URLSearchParams(window.location.search);
      const dev = urlParams.get("dev");
      return dev === "true";
    },
  },
  created() {
    const apps = this.getList("apps");
    this.$tis.addApps(apps);
    const widgets = this.getList("widgets");
    this.$tis.addWidgets(widgets);
  },
  methods: {
    getList(type) {
      let list = [];
      try {
        list = sessionStorage.getItem(type);
        list = JSON.parse(list);
      } catch (_) {
        list = [];
      }
      return Array.isArray(list) ? list : []
    },
    saveItem(item, type) {
      const list = this.getList(type);
      list.push(item);
      sessionStorage.setItem(type, JSON.stringify(list));
    },
    onAddWidget() {
      const invalid = Object.values(this.widget).some((v) => !v.trim());
      if (invalid) {
        return this.$message.error("参数不能为空");
      }
      this.$tis.addWidgets([this.widget]);
      this.saveItem(this.widget, "widgets");
      this.widget = {
        name: "",
        icon: "",
        id: "",
        entry: "",
        position: "",
      };
      this.$message.success("添加成功");
      this.show = false;
    },
    onAddApp() {
      const invalid = Object.values(this.app).some((v) => !v.trim());
      if (invalid) {
        return alert("参数不能为空");
      }
      this.$tis.addApps([this.app]);
      this.saveItem(this.app, "apps");
      this.app = {
        name: "",
        icon: "",
        id: "",
        entry: "",
      };
      this.$message.success("添加成功");
      this.show = false;
    },
    onToggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 501;
}

.devtools {
  z-index: 404;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 20px;

  .card {
    max-width: 500px;
  }
}
</style>