<template>
  <div class="app-bar-container" v-if="visible">
    <component :is="AppBar" />
  </div>
</template>

<script>
export default {
  computed: {
    AppBar() {
      return this.$tis.layouts.AppBar;
    },
    visible() {
      return !!this.AppBar;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-container {
  width: 100%;
  max-height: 70px;
  overflow: hidden;
}
</style>