<template>
  <div class="apps-panel-container" v-if="visible">
    <component :is="AppsPanel" />
  </div>
</template>

<script>
export default {
  computed: {
    AppsPanel() {
      return this.$tis.layouts.AppsPanel;
    },
    visible() {
      return !!this.AppsPanel && this.$tis.isVisibleAppsPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.apps-panel-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
}
</style>