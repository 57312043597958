<template>
  <div class="notification-whole-container" v-if="visible">
    <component :is="NotificationWhole" />
  </div>
</template>

<script>
export default {
  computed: {
    NotificationWhole() {
      return this.$tis.layouts.NotificationWhole;
    },
    visible() {
      console.log(!!this.NotificationWhole && this.$tis.isVisibleNotificationWhole())
      return !!this.NotificationWhole && this.$tis.isVisibleNotificationWhole();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-whole-container {
  position: fixed;
  top: 76px;
  bottom: 65px;
  right: 0;
  z-index: 200;
  width: 398px;
  // height: 100%;
}
</style>
