<template>
  <div class="widgets-view-container">
    <component :is="WidgetsView" />
  </div>
</template>

<script>
export default {
  computed: {
    WidgetsView() {
      return this.$tis.layouts.WidgetsView;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.widgets-view-container {
  // position: absolute;
  // top: 0px;
  // right: 0px;
  // height: 100%;
  // left: 0;
  // z-index: 100;
  // pointer-events: none;
}
</style>