<template>
  <div class="nar-bar-container" v-if="visible">
    <component :is="NavBar" />
  </div>
</template>

<script>
export default {
  computed: {
    NavBar() {
      return this.$tis.layouts.NavBar;
    },
    visible() {
      return !!this.NavBar;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
  width: 100%;
  max-height: 80px;
  overflow: hidden;
}
</style>