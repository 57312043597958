<template>
  <div class="widgets-setting-container" v-if="visible">
    <component :is="Login" />
  </div>
</template>

<script>
export default {
  computed: {
    Login() {
      return this.$tis.layouts.Login;
    },
    visible() {
      return !!this.Login && this.$tis.isVisibleWidgetsLoginPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets-setting-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
}
</style>