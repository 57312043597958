<template>
  <div class="page">
    <img src="../assets/header/收发警报.png" @click="onClick" />
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$tis.bus.alertSettingPageVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  z-index: 1000;
  text-align: center;
  img{
    width: 1200px;
    height: 800px;
  }
}
</style>

