import { render, staticRenderFns } from "./SiderContainer.vue?vue&type=template&id=4507c755&scoped=true&"
import script from "./SiderContainer.vue?vue&type=script&lang=js&"
export * from "./SiderContainer.vue?vue&type=script&lang=js&"
import style0 from "./SiderContainer.vue?vue&type=style&index=0&id=4507c755&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4507c755",
  null
  
)

export default component.exports