<template>
  <div  v-if="visible" v-clickoutside="handleClose" @mousedown="move">
    <component :is="SearchBox" />
  </div>
</template>


<script>
const clickoutside = {
    // 初始化指令
    bind(el, binding) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};



export default {
  data() {
    return {
      

    }

  },

    directives: {clickoutside},

  methods: {
    handleClose(){
        this.$tis.tggtoList()
    },
    move(e){
      // console.log(e);
      let odiv=e.target
      // console.log(odiv);
      let disX = e.clientX-odiv.offsetLeft;
      // console.log(disX);
      let disY = e.clientY - odiv.offsetTop;
      // console.log(disY);
     document.onmousemove = (e) => {
        let left = e.clientX - disX;
      // console.log(left);
      let top = e.clientY - disY;
      // console.log(top);
      odiv.style.left = left + 'px';
      odiv.style.top = top + 'px';
     };
     document.onmouseup = () => {
       document.onmousemove = null;
       document.onmouseup = null;
     }
    }


  },
  mounted() {
    
  },
  computed: {
    

    SearchBox() {
      return this.$tis.layouts.SearchBox;
    },
    visible() {
      return !!this.SearchBox && this.$tis.isVisibleSearchBox();
    },

  },
};
</script>

<style lang="scss" scoped>

</style>