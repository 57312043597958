<!--
 * @Author: your name
 * @Date: 2021-05-25 12:01:41
 * @LastEditTime: 2021-06-03 01:27:30
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /platform/src/components/border.vue
-->
<template>
	<el-dialog
		title="历史消息"
		:visible.sync="isVisible"
		:modal="false"
		custom-class="dialogBox"
		width="35%"
	>
		<div slot="title">
			系统设置
		</div>
		<div id="1221">这里面写你们的页面内容</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			isVisible: false,
		};
	},
	created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .dialogBox {
	background: black;
	border: 2px solid rgba(203, 185, 111, 0.39);
	border-image: -webkit-linear-gradient(#ccc, #f7c829) 30 30;
}
::v-deep .el-dialog__header {
	height: 32px;
	padding: 0;
	// color: black;
	background: url("../assets/border-top.png") no-repeat;
	background-size: 100% 100%;
	background-color: black;
	text-align: center;
	line-height: 32px;
}
::v-deep .el-dialog__headerbtn {
	top: 16px;
	.el-dialog__close {
		color: #fddb5c;
		font-weight: bold;
		font-size: 24px;
	}
}
</style>
