
<template>
  <div class="widgets-setting-container" v-if="visible">
    <component :is="SettingSys" />
  </div>
</template>

<script>
export default {
  computed: {
    SettingSys() {
      return this.$tis.layouts.SettingSys;
    },
    visible() {
      return !!this.SettingSys && this.$tis.isVisibleWidgetsSettingPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets-setting-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  background: rgba(0,0,0,0.8);
}
</style>